import {createStore} from 'vuex'
import persistedstate from 'vuex-persistedstate';


export default createStore({
    state: {
        // 是否全屏
        isFullScreen: false
    },
    mutations: {
        // 更新全屏状态
        SET_IS_FULL_SCREEN(state) {
            // 更改状态
            state.isFullScreen = !state.isFullScreen;

            // 全屏、退出全屏
            if (state.isFullScreen) {
                const docElm = document.documentElement;
                if (docElm.requestFullscreen) {
                    docElm.requestFullscreen();
                } else if (docElm.mozRequestFullScreen) {
                    docElm.mozRequestFullScreen();
                } else if (docElm.webkitRequestFullscreen) {
                    docElm.webkitRequestFullscreen();
                } else if (docElm.msRequestFullscreen) {
                    docElm.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    },
    // 持久化存储
    plugins: [persistedstate({storage: window.sessionStorage})]
})
